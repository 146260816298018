import acpAnimatedDropdownArrowTemplate from './templates/acp-animated-dropdown-arrow.html';
// @ngInject
function acpAnimatedDropdownArrowDirective() {
  return {
    restrict: 'E',
    scope: {
      open: '=',
      startDirection: '@'
    },
    template: acpAnimatedDropdownArrowTemplate
  };
}

export default acpAnimatedDropdownArrowDirective;
